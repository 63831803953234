

.dynamic-image-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  gap: 10px;
}

.image-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* flex-wrap: wrap; */
  min-width: 200px;
  max-width: 33%;
  margin: 5px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  /* white-space: nowrap; */
}

.image-grid-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  
}

.image-grid-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.image-grid-container::-webkit-scrollbar-thumb {
  background: var(--color-palette-white);
  border-radius: 10px;
}
.image-grid-container::-webkit-scrollbar-track {
  background: transparent;
}

.image {
  width: 95%;
  height: 100%;
  aspect-ratio: 0.666;
  /* padding: 20px; */
  /* height: 100%; */
  -o-object-fit: cover;
     object-fit: cover;
  margin: 5px;
  
}

.square-image{
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 200px;
  max-height: 200px;
  background-color: var(--color-palette-white);
  overflow: hidden;
   /* Apply smooth transition */
  filter: gray; /* IE5+ */
  -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
}

.square-image-container{
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 200px;
  max-height: 200px;
  background-color: var(--color-palette-white);
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* flex-grow: 1 */
  /* filter: grayscale(1); */

}


.square-image:active{
  -webkit-filter: none;
          filter: none;
  /* flex-basis: 400px; */
}

.square-image:hover{
  padding: 10px;
  max-width: 180px;
  max-height: 180px;
  border: 10px solid white;
  -webkit-clip-path: inset(22px 22px);
          clip-path: inset(22px 22px);
  /* transform: scale(1.1); */
  overflow: hidden;
  -ms-transform: scale(1.25);
      transform: scale(1.25);
  -webkit-transform: scale(1.25);

  filter: none;
  -webkit-filter: grayscale(0);
  -ms-flex-preferred-size: 400px;
      flex-basis: 400px;

}