



/* Apply basic styles to the navigation bar */
.navbar {
  width: 95%;
  height: calc(var(--base-width-size)*1.5);
  max-height: calc(var(--base-width-size)*1.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  text-align: center;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  color: var(--color-palette-white)
}

.stick-left{
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
}
.stick-right{
  -webkit-box-align: right;
      -ms-flex-align: right;
          align-items: right;
}
.stick-center{
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


/* Style for the title on the left */
.navbar-title {
  font-size: 24px; /* Change to desired font size */
  font-weight: bold;
}

/* Clear default margin from heading */
.navbar-title h1, .navbar-title h2, .navbar-title h3, .navbar-title h4, .navbar-title h5, .navbar-title h6 {
  margin: 0;
}