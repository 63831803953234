
.outlined-button {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 16px;
  border: 2px solid var(--color-palette-white);
  border-color: var(--color-palette-white);
  background-color: transparent;
  color: var(--color-palette-black);
  /* box-shadow: 0 0 0 1px var(--color-palette-white); */
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.outlined-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.outlined-button:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 2px var(--color-palette-white);
          box-shadow: 0 0 0 2px var(--color-palette-white);
}