

.flex-grid-col{
  /* Create a  */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.flex-grid-row{
  /* Create a  */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flex-grid-item{
  /* Create a  */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-row-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-col-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
}

.flex-row-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-col-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: right;
      -ms-flex-align: right;
          align-items: right;
}

.height-80{
  max-height: 80vh;

}

.flex-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.stick-right{
  margin-left: auto;
}

.flex-right{
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.flex-col-right{
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
}

.flex-wrap{
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}