
.image-card{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10px;
  width: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 400px;
}

.image-card-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.image-card img{
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  width: 200px;
}

.image-filled{
  /* width: 50%; */
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
  /* position: relative */
}

.image-filled img{
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  /* position: absolute; */
  /* left: 50%; */
  /* width: 100px;
  height: 100px; */
}