

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Poppins:wght@200;300;400;500&family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap');
:root {
  --base-width-size: calc(100vw / 12 - 2vw);
  --base-unit-size: calc(calc(100vw / 12 - 2vw) / 100 );

  --color-palette-1: 
  #ffd166;
  --color-palette-2: 
  #ef476f;
  --color-palette-3:
   #06d6a0;
  --color-palette-4: 
  #118ab2;
  --color-palette-black: 
  #000000;
  --color-palette-white: 
  #ffffff;

  --smoky-black: #0D0A05ff;
  --beaver: #9B7A69ff;
  --cinereous: #A5948Bff;
  --smoky-black-2: #16100Cff;
  --cinereous-2: #88776Cff;
  --cinereous-light: #a28368;
}

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  width: 100%;
}
.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* min-height: 90vh; */
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flex-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* border: 1px solid #454545; */
  max-width: 90%;
  min-height: 200px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.letter-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  border: 1px solid var(--color-palette-2);
  margin: 3px;
  /* font-size: 10vmin; */
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: var(--base-width-size);
  width: calc(var(--base-width-size));
  height: calc(var(--base-width-size));
  aspect-ratio: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: rgba(239, 71, 111, 0.4) 5px 5px, rgba(239, 71, 111, 0.3) 10px 10px, rgba(239, 71, 111, 0.2) 15px 15px, rgba(239, 71, 111, 0.1) 20px 20px, rgba(239, 71, 111, 0.05) 25px 25px;
          box-shadow: rgba(239, 71, 111, 0.4) 5px 5px, rgba(239, 71, 111, 0.3) 10px 10px, rgba(239, 71, 111, 0.2) 15px 15px, rgba(239, 71, 111, 0.1) 20px 20px, rgba(239, 71, 111, 0.05) 25px 25px;  

  -webkit-animation: bounce2 2s ease infinite;  

          animation: bounce2 2s ease infinite;
}

.no-link-decor{
  text-decoration: none;
}

.no-link-decor a {
  text-decoration: none;
}
.no-link-decor a:visited  {
  text-decoration: none;
}

@-webkit-keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);transform: translateY(-15px);}
}

@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);transform: translateY(-15px);}
}

@media (max-width: 1000px) {
  .letter-portrait {
    font-size: var(--base-width-size);
    width: calc(var(--base-width-size));
    height: calc(var(--base-width-size));
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.body {
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  /* border: 1px dotted #454545; */
  background-color: var(--color-palette-black);
  /* overflow: hidden; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  
}

.tborder {
  border: 1px none #454545;
}

/* Pallette: https://coolors.co/palette/33a8c7-52e3e1-a0e426-fdf148-ffab00-f77976-f050ae-d883ff-9336fd */

@-webkit-keyframes shake {
	0%, 100% {-webkit-transform: translateX(0);transform: translateX(0);}
	10%, 30%, 50%, 70%, 90% {-webkit-transform: translateX(-10px);transform: translateX(-10px);}
	20%, 40%, 60%, 80% {-webkit-transform: translateX(10px);transform: translateX(10px);}
}

@keyframes shake {
	0%, 100% {-webkit-transform: translateX(0);transform: translateX(0);}
	10%, 30%, 50%, 70%, 90% {-webkit-transform: translateX(-10px);transform: translateX(-10px);}
	20%, 40%, 60%, 80% {-webkit-transform: translateX(10px);transform: translateX(10px);}
}


.coming-soon {
  font-family: 'Dancing Script', cursive;
  font-weight: 200;
  font-size: calc(var(--base-width-size));
  text-shadow: var(--color-palette-2) calc(var(--base-unit-size) * 3) calc(var(--base-unit-size) * 5);
  color: var(--color-palette-1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.gradient-animation {
  -webkit-animation: typing 3.5s ease infinite;
          animation: typing 3.5s ease infinite;
}

@-webkit-keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.width-90{
  max-width: 90%;
}

.width-70{
  max-width: 70%;
}

.width-30{
  max-width: 30%;
}

.width-50{
  max-width: 50%;
}


.bio-table-width{
  max-width: 25%;
}

@media (orientation: portrait) {
  .p-flex-row{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .bio-table-width{
    max-width: 70%;
  }
}