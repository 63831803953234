

.jane-background-image {
  background-image: url("/public/images/anna/pexels-anna-kollor-14330802.png");
  -o-object-fit: cover;
     object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.jane-text-position {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.jane-content-cover {
  width: 80%;
  min-height: 60vh;
}

.jane-page-bg {
  background-color: var(--smoky-black);
}

.jane-text-color {
  color: var(--cinereous);
}

.jane-half-page {
  /* max-width:100%; */
  width: 49vh;
  max-height: 100vh;
  overflow: hidden;
  /* object-position: 0% -50%; */
  background-color: var(--smoky-black-2);
  /* height: 100%; */
  min-height: 100vh;
  width: 50%;
  margin: 0;
  padding: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* align-items: center; */
}

.jane-full-page-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.shift-left-50 {
  -o-object-position: 60% 0%;
     object-position: 60% 0%;
}

.jane-text-section {
  width: 80%;
  font-family: 'Amatic SC', cursive;
  color: var(--cinereous-light);
}

@media (max-width: 600px) {
  .p-flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .jane-half-page {
    width: 95%;
  }
}