

.text-title {
  font-family: 'Roboto', sans-serif;
  font-size: calc(var(--base-width-size)*1.2);
  font-weight: 100;
  text-transform: uppercase;
}

.text-title-m {
  font-family: 'Roboto', sans-serif;
  font-size: calc(var(--base-width-size)*1);
  font-weight: 100;
  text-transform: uppercase;
}

.text-h2{
  font-family: 'Roboto', sans-serif;
  font-size: max(calc(var(--base-width-size)*0.7),2rem);
  font-weight: 100;
  text-transform: uppercase;
}

.text-h4{
  font-family: 'Poppins', sans-serif;
  font-size: max(calc(var(--base-width-size)*0.3),3rem);
  font-weight: 200;
  text-transform: uppercase;
}

.text-normal{
  font-family: 'Poppins', sans-serif;
  font-size: min(max(calc(var(--base-width-size)*0.3),1rem), 1.2rem);
  font-weight: 400;
  text-align: justify;
}

.text-handwritten{
  font-family: 'Amatic SC', cursive;
}

.text-roboto {
  font-family: 'Roboto', sans-serif;
}

.text-kalam{
  font-family: 'Kalam', cursive;
}

.text-center {
  text-align: center;
}

.text-font-size-1{
  font-size: min(calc(var(--base-width-size)),90px);
}

.camelcase{
  text-transform: none;
}

.color-palette-white-tx {
  color: var(--color-palette-white);
}