
.carousel {
  max-width: 100%;
  margin: 10px;
  max-height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.slider {
  padding: 20px;
  margin-bottom: 10px;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  border-radius: var(--box-radius);
  /* background-color: white; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid var(--grey-color);
  /* height: 100%; */
}

.carousel-image {
  /* max-width: 90%; */
  /* width: 50%; */
  /* height: 80%; */

  /* height: ; */
  /* max-height: 80vh; */
  max-width: 80vw;
  /* display: flex; */
  -webkit-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  border-radius: var(--box-radius);
  aspect-ratio: 0.666;
  -o-object-fit: cover;
     object-fit: cover;
  border-color: var(--color-palette-white);
  border-width: 20px;
  border-style: solid;

}

.controls {
  max-width: 768px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  border-radius: var(--box-radius);
}

.carousel button {
  cursor: pointer;
  color: var(--color-palette-black);
  padding: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  border: 2px solid var(--color-palette-white);
  background-color: var(--color-palette-white);
  -webkit-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}

.carousel button svg {
  font-size: 1.3rem;
}

.carousel button:hover {
  background-color: var(--color-palette-white);
}

.carousel button:first-child {
  left: 10px;
}

.carousel button:last-child {
  right: 10px;
}

.carousel img:not(:first-child) {
  opacity: 0;
}

.indicator-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 10px;
  /* color: var(--color-palette-white); */
}

.dot {
  cursor: pointer;
  width: 8px;
  height: 8px;
  background-color: var(--color-palette-white);
  border-radius: 50%;
  margin: 0 5px;
  -webkit-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.dot.active {
  background-color: var(--color-palette-1);
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}

@media (min-width: 768px) {
  .carousel {
    max-width: 33%;
    height: 10%;
    aspect-ratio: 0.666;
  }
  .carousel-image{
    /* height: 500px;
    max-height: 500px; */
    max-width: calc(768px * 0.50);
  }
}