.table{
  margin: 5px;
  margin-top: 20px;
  border-collapse: collapse;
  height: auto;
}

.table td, th {
  border: 1px solid var(--color-palette-white);
  text-align: center;
  padding: 8px;
  margin: 0;
}

.table tr{
  margin: 0;
}

tr:nth-child(even) {
  background-color: transparent;
}